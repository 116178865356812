import PropTypes from 'prop-types';
import React from 'react';
import { compose, type, equals, cond, identity } from 'ramda';

const shouldRender = context => cond([
    [compose(equals('Boolean'), type), identity],
    [compose(equals('Function'), type), fn => fn(context)]
]);

const renderChildren = (children, context) => type(children) === 'Function' ? children(context) : children;

const propTypes = {
    condition: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
    ]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
    ]),
    context: PropTypes.object
};

/**
 *  Takes either a boolean or function that returns a boolean and conditionally renders its children.
 *
 *  If a function is passed as a child and the condition is true the function is called and the returned value of the function is rendered.
 *
 *  If a context is provided the context will be passed to the condition function and child function.
 */
class Conditional extends React.Component {
    render(){
        const { context, condition, children } = this.props;
        return shouldRender(context)(condition) ? renderChildren(children, context) : null;
    }
}

Conditional.propTypes = propTypes;
Conditional.defaultProps = {
    condition: false
};

export default Conditional;