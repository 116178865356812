import {
  clamp,
  add,
  subtract,
  negate,
} from 'ramda'

const latBound = clamp(-90, 90)
const lngBound = clamp(-180, 180)
const add1 = add(1)
const sub1 = subtract(1)

export const getLngLetter = (x0, x1) => {
  // default
  if (x0 === x1) {
    return 'w'
  }

  return x0 < 0 && x1 >= 0
    ? (180 - negate(x0) + 180 - x1) <= (negate(x0) + x1)
      ? 'e'
      : 'w'
    : x0 >= 0 && x1 < 0
      ? getLngLetter(x1, x0) === 'e'
        ? 'w'
        : 'e'
      : x0 < x1
        ? 'w'
        : 'e'
}

export const getBounds = ({ origin: { lng: x0, lat: y0 }, destination: { lng: x1, lat: y1 } }) => {
  const latLetter0 = y0 > y1 ? 'n' : 's'
  const latLetter1 = y0 <= y1 ? 'n' : 's'
  const lngLetter0 = getLngLetter(x0, x1)
  const lngLetter1 = lngLetter0 === 'w' ? 'e' : 'w'
  const lat0 = latLetter0 === 'n' ? latBound(add1(y0)) : latBound(sub1(y0))
  const lat1 = latLetter1 === 'n' ? latBound(add1(y1)) : latBound(sub1(y1))
  const lng0 = lngLetter0 === 'e' ? lngBound(add1(x0)) : lngBound(sub1(x0))
  const lng1 = lngLetter1 === 'e' ? lngBound(add1(x1)) : lngBound(sub1(x1))
  return {
    [`${latLetter0}${lngLetter0}`]: {
      lat: lat0,
      lng: lng0,
    },
    [`${latLetter1}${lngLetter1}`]: {
      lat: lat1,
      lng: lng1,
    },
  }
}
