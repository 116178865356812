import React, { Component } from "react";
import Smooch from 'smooch'

class TestContainer extends Component {
  render() {
    return <div className="app">
              <div className="app-header">
                <h2>Smooch Webview Date Picker Example</h2>
              </div>
              <div className="app-intro">
                To get started, type something into the chat window below.
              </div>
    </div>;
  }

  componentDidMount() {
    Smooch.init({
      appId: '5bd8e90ac59ee500221d467a'
    }).then(() => {
      Smooch.open();
    });
  }
}

export default TestContainer;