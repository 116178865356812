import React from 'react';
import MaskedInput from 'react-text-mask';

const TextMaskCustom = (mask, guide) => (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      guide={guide}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default TextMaskCustom;
