import { APP_SAGA_STARTED, APP_MOUNTED, WINDOW_RESIZED } from '../actions'

const defaultState = {
  name: 'smooch app',
  mounted: null,
  smooch_id: null,
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  supportNumber: 18885300334,
}

const App = (state = defaultState, action) => {
  switch (action.type) {
    case APP_SAGA_STARTED: {
      return { ...state, smooch_id: action.payload }
    }
    case APP_MOUNTED: {
      return { ...state, mounted: action.payload }
    }
    case WINDOW_RESIZED: {
      return { ...state, windowHeight: window.innerHeight, windowWidth: window.innerWidth }
    }
    default:
      return state
  }
}

export default App
