import React, { PureComponent } from 'react';

// MATERIAL UI COMPONENTS
import {
  withStyles,
  createStyles,
  Grid,
  Paper,
  Typography,
  Fab,
} from '../material'

const styles = theme => createStyles({
  // margin for origin and destination
  headerContainer: {
    marginLeft: 25,
    height: '100%',
  },
  with: {
    width: '100%',
    paddingRight: 0,
  },
  content: {
    width: '100%',
    paddingTop: 50,
    paddingLeft: 50,
  },
  paper: {
    backgroundColor: '#896954',
    width: '100%',
  },
  paperHeader: {
    color: theme.palette.primary.main,
  },
  title: {
    height: 50,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: 30,
  },
  body: {
    height: '100%',
    padding: 30,
  },
  bodyContent: {
    width: '100%',
    color: theme.white,
  },
  buttonSpacing: {
    height: '200px',
    marginTop: '50px',
    marginLeft: -20,
    '@media (min-height: 600px)': {
      height: '200px',
      marginTop: '200px',
      marginLeft: -20,
    },
  },
})

class ThankYouPage extends PureComponent {
  render() {
    const {
      classes,
      date,
      name,
      createAnotherQuote,
    } = this.props
    return (
      <Grid container direction="column" className={classes.with}>
        <Grid item>
          <Paper className={classes.paper} elevation={1}>
            <Grid className={classes.title} container alignItems="center" justify="space-between">
              <Grid item>
                <Typography variant="h6" className={classes.paperHeader}>{`Thanks, ${name}`}</Typography>
              </Grid>
            </Grid>
            <Grid className={classes.body} container alignItems="center" justify="space-between">
              <Grid item>
                <Typography className={classes.bodyContent}>
                  {`Your shipment is scheduled for ${date}.`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.bodyContent}>
                   A Shiplux manager will contact you soon to confirm the booking information.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container direction="row" justify="center" className={classes.buttonSpacing}>
          <Grid item>
            <Fab style={{ color: '#D9A682', backgroundColor: '#1B1816', border: '1px solid rgba(217,166,130,0.5)' }} variant="extended" onClick={createAnotherQuote}>
            CREATE ANOTHER QUOTE
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ThankYouPage)
