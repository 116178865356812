import React from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  TextField,
  Paper,
  MenuItem,
  withStyles,
  createStyles,
} from '../material'

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => {}, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ))}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

const styles = theme => createStyles({
  root: {
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  input: {},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

/**
 * @typedef {object} Props
 * @property {(value: any) => void} handleSuggestionsFetchRequested
 * @property {() => void} handleSuggestionsClearRequested
 * @property {(value: any) => void} handleChange
 * @property {string[]} suggestions
 * @property {string} value
 * @property {object} InputProps
 * @property {boolean} loading
 */

const IntegrationAutosuggest = (props) => {
  const {
    classes,
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleChange,
    suggestions,
    value,
    InputProps = {},
    // loading,
  } = props;
  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested: ({ value }) => handleSuggestionsFetchRequested(value),
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          value,
          onChange: (event, { newValue }) => handleChange(newValue),
          ...InputProps,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

export default withStyles(styles)(IntegrationAutosuggest);
