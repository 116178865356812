import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import app from './app'
import formState from './formState'
import deliveryInfo from './deliveryInfo'
import personalInfo from './personalInfo'
import vehicleInfo from './vehicleInfo'
import quoteDetails from './quoteDetails'

const rootReducer = history => combineReducers({
  app,
  formState,
  deliveryInfo,
  personalInfo,
  vehicleInfo,
  quoteDetails,
  router: connectRouter(history),
})

export default rootReducer
