import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as queryString from 'query-string';
import AppStore, { AppSaga, sagaMiddleware, history } from './store';
import App from './containers/App/App';
import TestContainer from './containers/TestContainer';
import * as serviceWorker from './serviceWorker';

// Testing Cloud builds 2
const routes = [
  {
    path: '/',
    component: TestContainer,
    exact: true,
  },
  {
    path: '/webview',
    component: App,
    exact: true,
  },
];

sagaMiddleware.run(AppSaga(queryString.parse(window.location.search)));
ReactDOM.render(
  <Provider store={AppStore}>
    <ConnectedRouter history={history}>
      <Router>
        <div>
          <Switch>
            {routes.map((route, i) => <Route key={i} {...route} />)}
          </Switch>
        </div>
      </Router>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. sd
// Learn more about service workers: http://bit.ly/CRA-PWAd
serviceWorker.unregister();
