import { fork } from 'redux-saga/effects'
import { vehicleInfoSaga, deliveryInfoSaga, nextStepSaga } from './task'

function* FormSaga() {
  yield fork(deliveryInfoSaga)
  yield fork(vehicleInfoSaga)
  yield fork(nextStepSaga)
}

export default FormSaga
