import React from 'react';

import {
  withStyles,
  createStyles,
  CircularProgress,
} from '../material'

const styles = theme => createStyles({
  loader: {
    margin: theme.spacing.unit * 2,
    position: 'relative',
  },
  loader1: {
    color: theme.white,
  },
  loader2: {
    color: theme.palette.primary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
})

const loader = (props) => {
  const { classes, size, thickness = 4 } = props
  return (
    <div className={classes.loader}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.loader1}
        size={size}
        thickness={thickness}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.loader2}
        size={size}
        thickness={thickness}
      />
    </div>
  )
}

export default withStyles(styles)(loader)
