import { createMuiTheme } from '@material-ui/core/styles';

const darkBrown = '#544236'
const lightBrown = '#896955'
const backgroungGrey = '#343332'
const white = '#ffffff'
const shipluxGold = '#D9A682'

const customTheme = {
  backgroundColor: '#1c1816',
  palette: {
    action: {
      active: lightBrown,
      disabled: '#abf189fa',
    },
    primary: {
      main: '#D9A682',
      dark: '#896955',
      light: '#4da700',
    },
    secondary: {
      main: '#f6f7f8',
      light: '#979797',
      dark: '#676767',
    },
    background: {
      paper: '#1C1816',
      default: '#1C1816',
    },
    error: {
      main: '#BF2034',
    },
    hover: {
      hover: darkBrown,
    },
  },
  shape: {
    // borderRadius: 30,
  },
  vehicleInfoDropDown: {
    backgroundColor: backgroungGrey,
  },
  darkBrown,
  lightBrown,
  white,
  typography: {
    useNextVariants: true,
    // header across form
    h6: {
      color: white,
      fontSize: 26,
      '@media (max-height: 600px)': {
        fontSize: 20,
      },
    },
    body1: {
      color: '#D9A682',
      fontSize: 16,
    },
    caption: {
      color: white,
    },
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiMenu: {
      paper: {
        backgroundColor: backgroungGrey,
      },
    },
    MuiInputLabel: {
      root: {
        color: shipluxGold,
        fontSize: 14,
        '@media (max-height: 600px)': {
          fontSize: 12,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '1px solid #D9A682',
          opacity: 0.3,
        },
        '&:before': {
          borderBottom: '1px solid #FFFFFF',
          opacity: 0.3,
        },
        '&:hover': {
          borderBottom: '1px solid #D9A682',
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: white,
      },
    },
    MuiSelect: {
      root: {
        color: white,
      },
    },
    // Make text color white
    MuiInputBase: {
      input: {
        color: white,
      },
    },
    // Calendar month header
    MuiTypography: {
      // body1: {
      //   color: '#D9A682',
      // },
    },
    // individual calendar dates
    MuiPickersDay: {
      day: {
        color: white,
      },
    },

    MuiPickersCalendarHeader: {
      // Calendar day of the week header
      dayLabel: {
        color: '#605D5C',
      },
    },
    // Calendar button for arrow icon
    // Calendar Background Color
    MuiPaper: {
      root: {
        backgroundColor: '#343332',
      },
    },
    // Switch for vehicle if running or not
    MuiSwitch: {
      // switchBase: {
      //   height: 22,
      //   width: 46,
      // },
      // icon: {
      //   backgroundColor: '#000000',
      //   width: 14,
      //   height: 14,
      // },
      // bar: {
      //   backgroundColor: '#ffffff',
      // },
      // colorPrimary: {
      //   '&$checked': {
      //     color: 'black',
      //     '& + $bar': {
      //       backgroundColor: '#fffffff',
      //       opacity: 1,
      //     },
      //   },
      // },
    },
  },
}
const appTheme = createMuiTheme(customTheme);

export default appTheme;
