import {
  GO_NEXT_STEP,
  CLOSE_ERROR_SNACKBAR,
  SHOW_ERROR_SNACKBAR,
  START_ANOTHER_QUOTE,
} from '../actions'

export const defaultState = {
  currentStep: 0,
  totalFormSteps: 3,
  showErrorSnackBar: false,
  snackBarMessage: '',
}

const FormState = (state = defaultState, action) => {
  switch (action.type) {
    case GO_NEXT_STEP:
      return { ...state, currentStep: state.currentStep + 1 }
    case CLOSE_ERROR_SNACKBAR:
      return { ...state, showErrorSnackBar: false }
    case SHOW_ERROR_SNACKBAR:
      return { ...state, showErrorSnackBar: true, snackBarMessage: action.payload }
    case START_ANOTHER_QUOTE:
      return { ...defaultState, currentStep: 1 }
    default:
      return state
  }
}

export default FormState
