import { put, fork } from 'redux-saga/effects';
import { APP_SAGA_STARTED } from '../actions'
import FormSaga from './form'
import WindowSaga from './window'
/** @typedef {{ smooch_id?: string }} AppSagaPayload */

/** @type { (payload: AppSagaPayload ) => () => import('redux-saga').SagaIterator } */
const AppSaga = ({ smooch_id = 'd7167fb793fd25d5ec22e212' }) => function* appSaga() {
  yield fork(WindowSaga)
  yield put({ type: APP_SAGA_STARTED, payload: smooch_id })
  yield fork(FormSaga)
}


export default AppSaga;
