
import React, { Component } from 'react';
import * as ReactRedux from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Helmet } from 'react-helmet';
import LuxonUtils from '@date-io/luxon';
import FormContainer from '../Form'
import theme from './theme';
import { APP_MOUNTED } from '../../actions'

class App extends Component {
  componentDidMount() {
    // @ts-ignore
    // eslint-disable-next-line
    window.webviewSdkInit;
    this.props.dispatch({ type: APP_MOUNTED, payload: Date.now() })
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <Helmet title="Get Shipping Quote" />
            <FormContainer />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({})

export default ReactRedux.connect(mapStateToProps)(App);
