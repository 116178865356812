import React, { PureComponent } from 'react';
import { DatePicker } from 'material-ui-pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { isEmpty, isNil } from 'ramda'

// MATERIAL UI COMPONENTS
import {
  withStyles,
  createStyles,
  Tabs,
  Tab,
  Grid,
  Typography,
} from '../material'

import AutoComplete from '../autocomplete'
import { EnclosedTrailer, OpenTrailer } from '../icons'


const styles = theme => createStyles({
  // margin for origin and destination
  formSpacing: {
    marginBottom: 24,
  },
  // calendar label
  calendarText: {
    color: '#ffffff',
    opacity: 0.3,
    fontSize: 16,
    marginBottom: 21,
    marginTop: 16,
  },
  // calendar input label
  calendarBorder: {
    borderBottom: 'none',
  },
  marginLabel: {
    marginBottom: 21,
  },
  // Transport Type Text
  transportText: {
    color: '#ffffff',
    opacity: 0.3,
    fontSize: 16,
    marginBottom: 20,
  },
  // Tab background color
  tabActiveColor: {
    backgroundColor: '#D9A682',
    height: 117,
    width: 120,
  },
  tabNonActiveColor: {
    backgroundColor: '#5C5B5B',
    height: 117,
    width: 120,
  },
})

const theme2 = outerTheme => ({
  ...outerTheme,
  overrides: {
    ...outerTheme.overrides,
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: '#343332',
        '& > *': {
          // label
          backgroundColor: '#343332',
        },
      },
      dayLabel: {
        color: '#D8D8D8',
        opacity: 0.4,
      },
    },
  },
});

class DeliveryInfo extends PureComponent {
  render() {
    const {
      classes,
      formData: {
        origin,
        originMeta,
        destination,
        destinationMeta,
        date,
        transportType,
        errors,
      },
      originAutoComplete,
      destinationAutoComplete,
      handleChange,
    } = this.props

    const openLabel = (
      <div>
        <OpenTrailer fill={transportType === 0 ? '#ffffff' : '#000000'} viewBox="0 0 34 28" style={{ marginTop: '28px', marginBottom: '24px' }} />
        <Typography> Open </Typography>
      </div>
    )
    const enclosedLabel = (
      <div>
        <EnclosedTrailer fill={transportType === 0 ? '#000000' : '#ffffff'} viewBox="0 0 34 22" style={{ marginTop: '28px', marginBottom: '24px' }} />
        <Typography> Enclosed </Typography>
      </div>
    )
    const originError = !isEmpty(origin) && isNil(originMeta) && isEmpty(originAutoComplete.suggestions)
    const destinationError = !isEmpty(destination) && isNil(destinationMeta) && isEmpty(destinationAutoComplete.suggestions)
    const originLabel = originError
      ? 'Please select option from dropdown'
      : errors.origin.status
        ? errors.origin.label
        : 'Pickup (city or ZIP and state)'
    const destinationLabel = destinationError
      ? 'Please select option from dropdown'
      : errors.destination.status
        ? errors.destination.label
        : 'Delivery (city or ZIP and state)'

    return (
      <Grid container direction="column">
        <Grid item className={classes.formSpacing}>
          <AutoComplete
            InputProps={{
              type: 'text',
              label: originLabel,
              error: originError || errors.origin.status,
            }}
            value={origin}
            suggestions={originAutoComplete.suggestions}
            loading={originAutoComplete.loading}
            handleChange={handleChange('origin')}
            handleSuggestionsFetchRequested={originAutoComplete.getSuggestions}
            handleSuggestionsClearRequested={originAutoComplete.clearSuggesions}
          />
        </Grid>
        <Grid item className={classes.formSpacing}>
          <AutoComplete
            marginTop="20"
            InputProps={{
              type: 'text',
              label: destinationLabel,
              error: destinationError || errors.destination.status,
            }}
            value={destination}
            suggestions={destinationAutoComplete.suggestions}
            loading={destinationAutoComplete.loading}
            handleChange={handleChange('destination')}
            handleSuggestionsFetchRequested={destinationAutoComplete.getSuggestions}
            handleSuggestionsClearRequested={destinationAutoComplete.clearSuggesions}
          />
        </Grid>
        <Grid item className={classes.formSpacing}>
          <Typography className={classes.calendarText}>Pickup Date</Typography>

          <MuiThemeProvider theme={theme2}>
            <DatePicker
              InputProps={{ disableUnderline: true }}
              keyboard
              value={date}
              onChange={handleChange('date')}
              format="MM/dd/yyyy"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiThemeProvider>
        </Grid>
        <Grid item>
          <Typography className={classes.transportText}>Transportation type</Typography>
          <Tabs
            className={classes.tabSize}
            value={transportType}
            onChange={(event, value) => handleChange('transportType')(value)}
            fullWidth
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab className={transportType === 0 ? `${classes.tabActiveColor}` : `${classes.tabNonActiveColor}`} label={openLabel} />
            <Tab className={transportType === 0 ? `${classes.tabNonActiveColor}` : `${classes.tabActiveColor}`} label={enclosedLabel} />
          </Tabs>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(DeliveryInfo)
