export const APP_SAGA_STARTED = 'APP_SAGA_STARTED'
export const APP_MOUNTED = 'APP_MOUNTED'
export const WINDOW_RESIZED = 'WINDOW_RESIZED'
export const FORM_UPDATE = 'FORM_UPDATE'
export const FORM_SUBMIT = 'FORM_SUBMIT'
export const NEXT_STEP_INITIATED = 'NEXT_STEP_INITIATED'
export const GO_NEXT_STEP = 'GO_NEXT_STEP'
export const DELIVERY_INFO_UPDATE = 'DELIVERY_INFO_UPDATE'
export const DELIVERY_INFO_GET_LOCATION_SUGGESTIONS = 'DELIVERY_INFO_GET_LOCATION_SUGGESTIONS'
export const DELIVERY_INFO_CLEAR_LOCATION_SUGGESTIONS = 'DELIVERY_INFO_CLEAR_LOCATION_SUGGESTIONS'
export const DELIVERY_INFO_GET_LOCATION_SUGGESTIONS_RESPONSE = 'DELIVERY_INFO_GET_LOCATION_SUGGESTIONS_RESPONSE'
export const SUBMIT_DELIVERY_INFO = 'SUBMIT_DELIVERY_INFO'
export const PERSONAL_INFO_UPDATE = 'PERSONAL_INFO_UPDATE'
export const SUBMIT_PERSONAL_INFO = 'SUBMIT_PERSONAL_INFO'
export const VEHICLE_INFO_UPDATE = 'VEHICLE_INFO_UPDATE'
export const VEHICLE_SUGGESTION_REQUEST = 'VEHICLE_SUGGESTION_REQUEST'
export const CLEAR_VEHICLE_SUGGESTION = 'CLEAR_VEHICLE_SUGGESTION'
export const GET_MODEL_MENU_RESPONSE = 'GET_MODEL_MENU_RESPONSE'
export const ADD_ANOTHER_VEHICLE = 'ADD_ANOTHER_VEHICLE'
export const CLOSE_ERROR_SNACKBAR = 'CLOSE_ERROR_SNACKBAR'
export const SHOW_ERROR_SNACKBAR = 'SHOW_ERROR_SNACKBAR'
export const SUBMIT_VEHICLE_INFO = 'SUBMIT_VEHICLE_INFO'
export const QUOTE_RESPONSE = 'QUOTE_RESPONSE'
export const QUOTE_CONFIRMATION_ERROR = 'QUOTE_CONFIRMATION_ERROR'
export const START_ANOTHER_QUOTE = 'START_ANOTHER_QUOTE'
