import {
  append,
  anyPass, equals,
  times, add, take, objOf,
  includes, update, isEmpty,
} from 'ramda'

import {
  VEHICLE_INFO_UPDATE,
  ADD_ANOTHER_VEHICLE,
  SUBMIT_VEHICLE_INFO,
  GET_MODEL_MENU_RESPONSE,
  VEHICLE_SUGGESTION_REQUEST,
  CLEAR_VEHICLE_SUGGESTION,
  START_ANOTHER_QUOTE,
} from '../actions'

import Makes from '../motorcyleMakes'
import { isObject } from '../utils'

const first5 = take(5)
const addCustom = values =>
  values.length < 5
    ? append({ label: 'Custom' }, values)
    : update(4, { label: 'Custom' }, values)

const getSuggestion = suggestions => (value) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : first5(
      suggestions.filter(
        suggestion => suggestion.label.slice(0, inputLength).toLowerCase() === inputValue,
      ),
    )
}

const yearRange = (new Date()).getFullYear() - 1900
const years = times(add(1900), yearRange + 1).map(year => year.toString())
const getSuggestData = {
  make: getSuggestion(Makes.map(objOf('label'))),
  year: getSuggestion(
    years.map(objOf('label')),
  ),
}

const defaultState = {
  vehicles: [],
  currentVehicle: {
    year: '',
    validYear: false,
    make: '',
    validMake: false,
    model: '',
    running: true,
  },
  modelMenu: {
    loaded: false,
    loading: false,
    data: [],
  },
  suggestions: {
    year: [],
    make: [],
  },
  errors: {
    hasErrors: false,
    year: {
      status: false,
      label: '',
    },
    make: {
      status: false,
      label: '',
    },
    model: {
      status: false,
      label: '',
    },
  },
}

/**
 * @typedef { typeof defaultState } State
 * @type { (state: State) => State } */
const addVehicle = (state) => {
  const vehicles = append(state.currentVehicle, state.vehicles)
  const currentVehicle = { ...defaultState.currentVehicle }
  return {
    ...state, vehicles, currentVehicle, modelMenu: { ...defaultState.modelMenu },
  }
}
const isValidMake = value => isObject(value) || includes(value, Makes) || value === 'Custom'
const isValidYear = value => isObject(value) || includes(value, years)
const isMakeOrYear = anyPass([equals('make'), equals('year')])
const hasValidMakeAndYear = ({ validMake, validYear }) => validMake && validYear

const VehicleInfo = (state = defaultState, action) => {
  switch (action.type) {
    case VEHICLE_INFO_UPDATE: {
      const { name, value } = action.payload
      const currentVehicle = {
        ...state.currentVehicle,
        [name]: isObject(value) ? value.label : value,
        validYear: name === 'year' ? isValidYear(value) : state.currentVehicle.validYear,
        validMake: name === 'make' ? isValidMake(value) : state.currentVehicle.validMake,
      }
      const makeOrYear = isMakeOrYear(name)
      const modelMenu = makeOrYear && hasValidMakeAndYear(currentVehicle)
        ? { ...defaultState.modelMenu, loading: currentVehicle.make !== 'Custom' }
        : makeOrYear
          ? { ...defaultState.modelMenu }
          : state.modelMenu
      return {
        ...state,
        currentVehicle: makeOrYear
          ? { ...currentVehicle, model: '' }
          : currentVehicle,
        modelMenu,
        errors: {
          year: name === 'year' ? defaultState.errors.year : state.errors.year,
          make: name === 'make' ? defaultState.errors.make : state.errors.make,
          model: name === 'model' ? defaultState.errors.model : state.errors.model,
        },
      }
    }
    case VEHICLE_SUGGESTION_REQUEST: {
      const { name, value } = action.payload
      const values = getSuggestData[name](value)
      return { ...state, suggestions: { ...state.suggestions, [name]: name === 'make' ? addCustom(values) : values } }
    }
    case CLEAR_VEHICLE_SUGGESTION: {
      const { name } = action.payload
      return { ...state, suggestions: { ...state.suggestions, [name]: [] } }
    }
    case GET_MODEL_MENU_RESPONSE: {
      return { ...state, modelMenu: { loaded: true, loading: false, data: action.payload } }
    }
    case ADD_ANOTHER_VEHICLE:
      return addVehicle(state)
    case SUBMIT_VEHICLE_INFO: {
      // input validation can happen here
      const {
        currentVehicle: {
          year,
          validYear,
          make,
          validMake,
          model,
        },
        vehicles,
      } = state

      // default case where they selected add another vehicle but did not fill out any fields
      if (isEmpty(year) && isEmpty(make) && isEmpty(model) && !isEmpty(vehicles)) {
        return { ...state, errors: { ...state.errors, hasErrors: false } }
      }

      if (validYear && validMake && (!isEmpty(model) || make === 'Custom')) {
        return { ...addVehicle(state), errors: { ...state.errors, hasErrors: false } }
      }

      const yearError = { status: !validYear || isEmpty(year), label: isEmpty(year) ? 'Vehicle year is required' : '' }
      const makeError = { status: !validMake || isEmpty(make), label: isEmpty(make) ? 'Vehicle make is required' : '' }
      const modelError = { status: isEmpty(model), label: 'Vehicle Model is required' }

      return {
        ...state,
        errors: {
          hasErrors: true,
          year: yearError,
          make: makeError,
          model: modelError,
        },
      }
    }
    case START_ANOTHER_QUOTE:
      return { ...defaultState }
    default:
      return state
  }
}

export default VehicleInfo
