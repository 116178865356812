import {
  takeEvery, select, call, put, fork,
} from 'redux-saga/effects'
import {
  always, cond,
  equals, pick,
  map, path,
  dissoc,
} from 'ramda'

import { formatIsoDate } from '../../utils';
import {
  VEHICLE_INFO_UPDATE,
  GET_MODEL_MENU_RESPONSE,
  SUBMIT_VEHICLE_INFO,
  SUBMIT_DELIVERY_INFO,
  SUBMIT_PERSONAL_INFO,
  GO_NEXT_STEP,
  NEXT_STEP_INITIATED,
  DELIVERY_INFO_GET_LOCATION_SUGGESTIONS,
  DELIVERY_INFO_GET_LOCATION_SUGGESTIONS_RESPONSE,
  QUOTE_RESPONSE,
  QUOTE_CONFIRMATION_ERROR,
  SHOW_ERROR_SNACKBAR,
} from '../../actions'

import {
  getVehicleModel,
  getLocationPredictions,
  createQuote,
  confirmQuote,
} from '../../services'

export function* handleVehicleInfoUpdate() {
  const {
    currentVehicle,
    modelMenu,
  } = yield select(state => pick(['currentVehicle', 'modelMenu'], state.vehicleInfo))

  if (modelMenu.loading) {
    const response = yield call(getVehicleModel, pick(['year', 'make'], currentVehicle))
    yield put({ type: GET_MODEL_MENU_RESPONSE, payload: response })
  }
}

export function* handleDeliveryInfoGetLocationSuggestions(action) {
  const { name, value } = action.payload
  const response = yield call(getLocationPredictions, { search: value })

  yield put({
    type: DELIVERY_INFO_GET_LOCATION_SUGGESTIONS_RESPONSE,
    payload: { name, value: response.predictions },
  })
}

export function* submitFormData() {
  const {
    app: { smooch_id: smoochId },
    deliveryInfo: {
      transportType, date, originMeta, destinationMeta,
    },
    personalInfo: { name, number, email },
    vehicleInfo: { vehicles },
  } = yield select(pick(['app', 'deliveryInfo', 'personalInfo', 'vehicleInfo']))

  const payload = {
    name,
    email,
    number: number.match(/\d+/g).join(''),
    date: formatIsoDate(date),
    vehicles: map(({
      year, make, model, running,
    }) => ({
      vehicleYear: year.toString(),
      vehicleMake: make,
      vehicleModel: model,
      running: Number(running).toString(),
    }), vehicles),
    transportType: transportType.toString(),
    origin: { ...originMeta.value, placeid: originMeta.placeid },
    destination: { ...destinationMeta.value, placeid: destinationMeta.placeid },
    smoochId,
  }
  const response = yield call(createQuote, payload)
  yield put({ type: QUOTE_RESPONSE, payload: { quoteDetails: response, error: null } })
  yield put({ type: GO_NEXT_STEP })
}

export function* handleConfirmQuote() {
  const {
    id: quoteId,
    destination,
    origin,
  } = yield select(path(['quoteDetails', 'quoteDetails']))
  const removeLocation = dissoc('location')
  const payload = {
    quoteId,
    shippingDetails: {
      destination: removeLocation(destination),
      origin: removeLocation(origin),
    },
  }
  const response = yield call(confirmQuote, payload)

  if (response.status === 'OK') {
    yield put({ type: GO_NEXT_STEP })
  } else {
    yield put({ type: QUOTE_CONFIRMATION_ERROR })
  }
}

export function* handleVehicleInfoSubmit() {
  yield put({ type: SUBMIT_VEHICLE_INFO })
  const hasErrors = yield select(path(['vehicleInfo', 'errors', 'hasErrors']))
  if (!hasErrors) {
    yield put({ type: GO_NEXT_STEP })
  } else {
    yield put({ type: SHOW_ERROR_SNACKBAR, payload: 'Please fix the errors on this page to continue' })
  }
}

export function* handleDeliveryInfoSubmit() {
  yield put({ type: SUBMIT_DELIVERY_INFO })
  const hasErrors = yield select(path(['deliveryInfo', 'errors', 'hasErrors']))
  if (!hasErrors) {
    yield put({ type: GO_NEXT_STEP })
  } else {
    yield put({ type: SHOW_ERROR_SNACKBAR, payload: 'Please fix the errors on this page to continue' })
  }
}

export function* handlePersonalInfoSubmit() {
  yield put({ type: SUBMIT_PERSONAL_INFO })
  const hasErrors = yield select(path(['personalInfo', 'errors', 'hasErrors']))
  if (!hasErrors) {
    yield fork(submitFormData)
  } else {
    yield put({ type: SHOW_ERROR_SNACKBAR, payload: 'Please fix the errors on this page to continue' })
  }
}
export const nextStepInitiatedHandler = cond([
  [equals(0), always(put({ type: GO_NEXT_STEP }))],
  [equals(1), always(fork(handleVehicleInfoSubmit))],
  [equals(2), always(fork(handleDeliveryInfoSubmit))],
  [equals(3), always(fork(handlePersonalInfoSubmit))],
  [equals(4), always(fork(handleConfirmQuote))],
])

export function* handleNextStepInitiation() {
  const currentStep = yield select(state => state.formState.currentStep)
  yield nextStepInitiatedHandler(currentStep)
}

export function* nextStepSaga() {
  yield takeEvery(NEXT_STEP_INITIATED, handleNextStepInitiation)
}

export function* vehicleInfoSaga() {
  yield takeEvery(VEHICLE_INFO_UPDATE, handleVehicleInfoUpdate)
}

export function* deliveryInfoSaga() {
  yield takeEvery(DELIVERY_INFO_GET_LOCATION_SUGGESTIONS, handleDeliveryInfoGetLocationSuggestions)
}
