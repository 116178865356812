import React, { PureComponent } from 'react';

// MATERIAL UI COMPONENTS
import {
  withStyles,
  TextField,
  Grid,
} from '../material'

import TextMaskCustom from '../textMask'


const styles = theme => ({
  formInput: {

  },
})

const PhoneMask = TextMaskCustom(
  ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  true,
)

class PersonalInfo extends PureComponent {
  phoneInput = React.createRef()

  // used to set the cursor inside the parenthesies of the phone mask
  setPhoneInputCursor = () => {
    const element = this.phoneInput.current;
    if (element.setSelectionRange) {
      element.setSelectionRange(1, 1);
    }
  }

  render() {
    const {
      classes,
      formData: {
        name,
        email,
        number,
        errors,
      },
      handleChange,
    } = this.props
    return (
      <form>
        <Grid container direction="column" justify="space-evenly">
          <Grid item>
            <TextField
              className={classes.formInput}
              margin="normal"
              type="text"
              label={`Your Name${errors.name ? ' (Name is required)' : ''}`}
              error={errors.name}
              value={name}
              onChange={event => handleChange('name')(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.formInput}
              margin="normal"
              label={`Email${errors.email ? ' (Valid Email is Required)' : ''}`}
              error={errors.email}
              value={email}
              autoComplete="email"
              name="email"
              onChange={event => handleChange('email')(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              InputProps={{
                inputComponent: PhoneMask,
                inputRef: this.phoneInput,
              }}
              className={classes.formInput}
              margin="normal"
              type="text"
              label={`Number${errors.number ? ' (Number is required)' : ''}`}
              error={errors.number}
              value={number}
              name="PhoneNumber"
              onClick={this.setPhoneInputCursor}
              onChange={event => handleChange('number')(event.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default withStyles(styles)(PersonalInfo)
