import { call, take, cancel, fork, put } from 'redux-saga/effects'
import { delay, eventChannel } from 'redux-saga'

import { WINDOW_RESIZED } from '../../actions'

export const windowResizedChannel = () => {
    return eventChannel(emit => {
        let func = () => emit(true);
        window.addEventListener('resize', func);
        return () => {
            func = () => { };
        };
    });
};

export function* handleWindowSizeChange() {
    yield call(delay, 100);
    yield put({ type: WINDOW_RESIZED });
};

export function* contentResizedSaga() {
    let task;
    const chan = windowResizedChannel();
    while (true) {
        yield take(chan);
        if (task) {
            cancel(task);
        }
        task = yield fork(handleWindowSizeChange);
    }
}

function* windowSaga(){
  yield fork(contentResizedSaga)
}

export default windowSaga
