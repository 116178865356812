import { isObject } from '../utils'

const SAGA_ACTION = 'SAGA_ACTION'
const SAGA_META = 'SAGA_META'

/** wrap dispatched actions that are meant only for saga middleware with this function to prevent action from being dispatched to the redux store */
export const sagaAction = action => ({ ...action, [SAGA_META]: { [SAGA_ACTION]: true } })

/** adding this middleware gives a performance boost for actions meant to only be dispatched to a saga and not update redux store */
export const sagaActionMiddleWare = store => next => action => isObject(action[SAGA_META]) && action[SAGA_META][SAGA_ACTION] === true ? 0 : next(action)

export default sagaActionMiddleWare