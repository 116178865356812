import {
  isEmpty,
  isNil,
} from 'ramda'

import {
  DELIVERY_INFO_UPDATE,
  SUBMIT_DELIVERY_INFO,
  DELIVERY_INFO_GET_LOCATION_SUGGESTIONS,
  DELIVERY_INFO_CLEAR_LOCATION_SUGGESTIONS,
  DELIVERY_INFO_GET_LOCATION_SUGGESTIONS_RESPONSE,
  START_ANOTHER_QUOTE,
} from '../actions'
import { isObject } from '../utils'

const defaultState = {
  origin: '',
  originMeta: null,
  destination: '',
  destinationMeta: null,
  date: (new Date()).toISOString(),
  transportType: 0,
  suggestions: {
    origin: {
      value: [],
      loading: false,
    },
    destination: {
      value: [],
      loading: false,
    },
  },
  errors: {
    hasErrors: false,
    origin: {
      status: false,
      label: '',
    },
    destination: {
      status: false,
      label: '',
    },
  },
}

const DeliveryInfo = (state = defaultState, action) => {
  switch (action.type) {
    case DELIVERY_INFO_UPDATE: {
      const { name, value } = action.payload
      const payload = {
        [name]: (name === 'origin' || name === 'destination') && isObject(value) ? value.label : value,
        originMeta: name === 'origin'
          ? isObject(value)
            ? value
            : null
          : state.originMeta,
        destinationMeta: name === 'destination'
          ? isObject(value)
            ? value
            : null
          : state.destinationMeta,
        errors: {
          origin: name === 'origin' ? defaultState.errors.origin : state.errors.origin,
          destination: name === 'destination' ? defaultState.errors.destination : state.errors.destination,
        },
      }
      return { ...state, ...payload }
    }
    case SUBMIT_DELIVERY_INFO: {
      const {
        origin,
        originMeta,
        destination,
        destinationMeta,
        date,
      } = state

      if (!isNil(originMeta) && !isNil(destinationMeta) && !isEmpty(date)) {
        return { ...state, errors: { ...state.errors, hasErrors: false } }
      }
      const originError = { status: isNil(originMeta), label: isEmpty(origin) ? 'Pickup location is required' : '' }
      const destinationError = { status: isNil(destinationMeta), label: isEmpty(destination) ? 'Destination location is required' : '' }
      return { ...state, errors: { hasErrors: true, origin: originError, destination: destinationError } }
    }
    case DELIVERY_INFO_GET_LOCATION_SUGGESTIONS: {
      const { name } = action.payload
      const suggObj = { ...state.suggestions[name], loading: true }
      return { ...state, suggestions: { ...state.suggestions, [name]: suggObj } }
    }
    case DELIVERY_INFO_CLEAR_LOCATION_SUGGESTIONS: {
      const { name } = action.payload
      const suggObj = { value: [], loading: false }
      return { ...state, suggestions: { ...state.suggestions, [name]: suggObj } }
    }
    case DELIVERY_INFO_GET_LOCATION_SUGGESTIONS_RESPONSE: {
      const { name, value } = action.payload
      const suggObj = { value, loading: false }
      return { ...state, suggestions: { ...state.suggestions, [name]: suggObj } }
    }
    case START_ANOTHER_QUOTE: {
      return { ...defaultState }
    }
    default:
      return state
  }
}

export default DeliveryInfo
