import React, { PureComponent } from 'react';
import classNames from 'classnames';

// MATERIAL UI COMPONENTS
import {
  withStyles,
  createStyles,
  ErrorIcon,
  Snackbar,
  SnackbarContent,
  CloseIcon,
  IconButton,
} from '../material'

const styles = theme => createStyles({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});
class ErrorSnackBar extends PureComponent {
  render() {
    const {
      classes,
      message,
      open,
      handleClose,
    } = this.props
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classes.error}
          aria-describedby="client-snackbar"
          message={
            (
              <span id="client-snackbar" className={classes.message}>
                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                {message}
              </span>
            )
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

export default withStyles(styles)(ErrorSnackBar)
