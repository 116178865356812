import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  compose as λ,
  pick,
  prop,
  flip,
  cond,
  equals,
  always,
  isEmpty,
} from 'ramda'

// material ui components
import {
  Grid,
  withStyles,
  createStyles,
} from '../../components/material';

import {
  FORM_SUBMIT,
  FORM_UPDATE,
  NEXT_STEP_INITIATED,
  VEHICLE_INFO_UPDATE,
  ADD_ANOTHER_VEHICLE,
  DELIVERY_INFO_UPDATE,
  PERSONAL_INFO_UPDATE,
  DELIVERY_INFO_CLEAR_LOCATION_SUGGESTIONS,
  DELIVERY_INFO_GET_LOCATION_SUGGESTIONS,
  VEHICLE_SUGGESTION_REQUEST,
  CLEAR_VEHICLE_SUGGESTION,
  CLOSE_ERROR_SNACKBAR,
  START_ANOTHER_QUOTE,
} from '../../actions';

import { addDaysToFormattedDate, formatIsoDate } from '../../utils'

import { sagaAction } from '../../store/sagaActionMiddleware'
// add declaration files for these components
import FormStep from '../../components/formStep'
import DeliveryInfo from '../../components/deliveryInfo'
import PersonalInfo from '../../components/personalInfo'
import VehicleInfo from '../../components/vehicleInfo'
import QuoteDetails from '../../components/quoteDetails'
import SnackBarError from '../../components/snackbarError'
import PreLoader from '../../components/preLoader'
import ThankYouPage from '../../components/thankYouPage'

const styles = theme => createStyles({
  formContainer: {
    width: '100%',
    backgroundColor: theme.backgroundColor,
    padding: 50,
  },
  noPadding: {
    padding: 0,
  },
  introContainer: {
    backgroundColor: '#000000',
  },
  thankYouContainer: {
    width: '100%',
    backgroundColor: theme.backgroundColor,
    paddingLeft: 50,
    paddingTop: 50,
    paddingBottom: 50,
    paddingRight: 0,
  },
})


const stepComponentes = {
  intro: props => <PreLoader {...props} />,
  quoteDetails: props => <QuoteDetails {...props} />,
  thankYouPage: props => <ThankYouPage {...props} />,
  deliveryInfo: ({ containerPayload, componentPayload }) => (
    <FormStep {...containerPayload}>
      <DeliveryInfo {...componentPayload} />
    </FormStep>
  ),
  personalInfo: ({ containerPayload, componentPayload }) => (
    <FormStep {...containerPayload}>
      <PersonalInfo {...componentPayload} />
    </FormStep>
  ),
  vehicleInfo: ({ containerPayload, componentPayload }) => (
    <FormStep {...containerPayload}>
      <VehicleInfo {...componentPayload} />
    </FormStep>
  ),
}

const steps = ['intro', 'vehicleInfo', 'deliveryInfo', 'personalInfo', 'quoteDetails', 'thankYouPage']

/** @type { (name: string) => React.ComponentType<any>} */
// @ts-ignore
const getStepByName = flip(prop)(stepComponentes)

/** @type { (index: number) => string} */
// @ts-ignore
const getStepName = flip(prop)(steps)

/** takes a step number and returns the mainComponent for that step */
const getStepComponent = λ(
  getStepByName,
  getStepName,
)

const getVehicleInfoPayload = ({
  formState, vehicleInfo: { suggestions, ...vehicleInfo },
  onNextStep, handleChange, onAddVehicle,
  getVehicleDetailsSuggestions, clearVehicleDetailsSuggestions,
}) => {
  const containerPayload = {
    step: formState.currentStep,
    totalSteps: formState.totalFormSteps,
    title: 'Vehicle info',
    onNextStep,
  }
  const componentPayload = {
    ...vehicleInfo,
    handleChange,
    onAddVehicle,
    yearAutoComplete: {
      suggestions: suggestions.year,
      getSuggestions: getVehicleDetailsSuggestions('year'),
      clearSuggesions: clearVehicleDetailsSuggestions('year'),
    },
    makeAutoComplete: {
      suggestions: suggestions.make,
      getSuggestions: getVehicleDetailsSuggestions('make'),
      clearSuggesions: clearVehicleDetailsSuggestions('make'),
    },
  }
  return { containerPayload, componentPayload }
}

const getDeliveryInfoPayload = ({
  formState, deliveryInfo: { suggestions, ...deliveryInfo },
  onNextStep, handleChange,
  getLocationSuggestions, clearLocationSuggestions,
}) => {
  const containerPayload = {
    step: formState.currentStep,
    totalSteps: formState.totalFormSteps,
    title: 'Delivery info',
    onNextStep,
  }
  const componentPayload = {
    formData: { ...deliveryInfo },
    handleChange,
    originAutoComplete: {
      suggestions: suggestions.origin.value,
      loading: suggestions.origin.loading,
      getSuggestions: getLocationSuggestions('origin'),
      clearSuggesions: clearLocationSuggestions('origin'),
    },
    destinationAutoComplete: {
      suggestions: suggestions.destination.value,
      loading: suggestions.destination.loading,
      getSuggestions: getLocationSuggestions('destination'),
      clearSuggesions: clearLocationSuggestions('destination'),
    },
  }
  return { containerPayload, componentPayload }
}

const getPersonalInfoPayload = ({
  formState, personalInfo, onNextStep, handleChange,
}) => {
  const containerPayload = {
    step: formState.currentStep,
    totalSteps: formState.totalFormSteps,
    title: 'Personal info',
    onNextStep,
  }
  const componentPayload = {
    formData: { ...personalInfo },
    handleChange,
  }
  return { containerPayload, componentPayload }
}

const getQuoteDetailsPayload = ({
  shipping_type: shippingType,
  shipDate,
  price,
  vehicles,
  origin,
  destination,
  onNextStep,
  support,
}) => {
  const vehiclesFormatted = vehicles.map(v => ({
    primaryLabel: `${v.vehicleMake}${isEmpty(v.vehicleModel) ? ` ${v.vehicleYear}` : ''}`,
    secondaryLabel: `${isEmpty(v.vehicleModel) ? '' : `${v.vehicleModel} ${v.vehicleYear}`}`,
  }))
  const originLabel = `${
    isEmpty(origin.city) ? `${origin.state}, ${origin.zip}`
      : `${origin.city}, ${origin.state} ${origin.zip}`}`

  const destinationLabel = `${
    isEmpty(destination.city) ? `${destination.state}, ${destination.zip}`
      : `${destination.city}, ${destination.state} ${destination.zip}`}`

  return {
    vehiclesFormatted,
    origin: { ...origin, originLabel },
    destination: { ...destination, destinationLabel },
    trailer: shippingType === 0 ? 'Open' : 'Enclosed',
    price: `$${(price.total * vehicles.length).toFixed(2)}`,
    pickupFormatted: `${shipDate} - ${addDaysToFormattedDate(5)(shipDate)}`,
    shipDate,
    onNextStep,
    support,
  }
}

const FormContainer = (props) => {
  const {
    classes,
    app,
    formState,
    deliveryInfo,
    personalInfo,
    vehicleInfo,
    quoteDetails,
    getVehicleDetailsSuggestions,
    clearVehicleDetailsSuggestions,
    getLocationSuggestions,
    clearLocationSuggestions,
    onNextStep,
    onVehicleInfoUpdate,
    onAddVehicle,
    onDeliveryInfoUpdate,
    onPersonalInfoUpdate,
    closeErrorSnackBar,
    createAnotherQuote,
  } = props

  const {
    currentStep,
  } = formState

  const stepName = getStepName(currentStep)
  const stepPayload = cond([
    [
      equals('intro'),
      () => ({
        onNextStep,
      }),
    ],
    [
      equals('vehicleInfo'),
      () => getVehicleInfoPayload({
        formState,
        vehicleInfo,
        onNextStep,
        handleChange: onVehicleInfoUpdate,
        getVehicleDetailsSuggestions,
        clearVehicleDetailsSuggestions,
        onAddVehicle,
      }),
    ],
    [
      equals('deliveryInfo'),
      () => getDeliveryInfoPayload({
        formState,
        deliveryInfo,
        onNextStep,
        handleChange: onDeliveryInfoUpdate,
        getLocationSuggestions,
        clearLocationSuggestions,
      }),
    ],
    [
      equals('personalInfo'),
      () => getPersonalInfoPayload({
        formState,
        personalInfo,
        onNextStep,
        handleChange: onPersonalInfoUpdate,
      }),
    ],
    [
      equals('quoteDetails'),
      () => getQuoteDetailsPayload({
        ...quoteDetails.quoteDetails,
        onNextStep,
        support: app.supportNumber,
      }),
    ],
    [
      equals('thankYouPage'),
      () => ({
        createAnotherQuote,
        date: formatIsoDate(deliveryInfo.date),
        name: personalInfo.name,
      }),
    ],
    // TODO implemeant detail and thankyou steps
    [always(true), always({})],
  ])(stepName)

  const Step = getStepComponent(currentStep)

  return (
    <Grid container justify="center" className={`${classes.formContainer} ${stepName === 'quoteDetails' || stepName === 'intro' ? classes.noPadding : ''} ${stepName === 'intro' ? classes.introContainer : ''} ${stepName === 'thankYouPage' ? classes.thankYouContainer : ''}`} style={{ minHeight: app.windowHeight }}>
      <Grid item style={{ width: '100%' }}>
        <Step {...stepPayload} />
      </Grid>
      <SnackBarError message={formState.snackBarMessage} open={formState.showErrorSnackBar} handleClose={closeErrorSnackBar} />
    </Grid>
  )
}

/** this is extracting the reducers provided in the array
 * and making them availble via this.props in the container component */
const mapStateToprops = pick(['formState', 'deliveryInfo', 'personalInfo', 'vehicleInfo', 'app', 'quoteDetails'])

const mapDispatchToProps = dispatch => ({
  getLocationSuggestions: name => value => dispatch({
    type: DELIVERY_INFO_GET_LOCATION_SUGGESTIONS,
    payload: { name, value },
  }),
  clearLocationSuggestions: name => () => dispatch({
    type: DELIVERY_INFO_CLEAR_LOCATION_SUGGESTIONS,
    payload: { name },
  }),
  getVehicleDetailsSuggestions: name => value => dispatch({
    type: VEHICLE_SUGGESTION_REQUEST,
    payload: { name, value },
  }),
  clearVehicleDetailsSuggestions: name => () => dispatch({
    type: CLEAR_VEHICLE_SUGGESTION,
    payload: { name },
  }),
  onFormUpdate: name => event => dispatch({
    type: FORM_UPDATE,
    payload: { name, value: event.target.value },
  }),
  onFormSubmit: () => dispatch({
    type: FORM_SUBMIT,
  }),
  onNextStep: () => dispatch(sagaAction({
    type: NEXT_STEP_INITIATED,
  })),
  onVehicleInfoUpdate: name => value => dispatch({
    type: VEHICLE_INFO_UPDATE,
    payload: { name, value },
  }),
  onAddVehicle: () => dispatch({
    type: ADD_ANOTHER_VEHICLE,
  }),
  closeErrorSnackBar: () => dispatch({
    type: CLOSE_ERROR_SNACKBAR,
  }),
  onDeliveryInfoUpdate: name => value => dispatch({
    type: DELIVERY_INFO_UPDATE,
    payload: { name, value },
  }),
  onPersonalInfoUpdate: name => value => dispatch({
    type: PERSONAL_INFO_UPDATE,
    payload: { name, value },
  }),
  createAnotherQuote: () => dispatch({
    type: START_ANOTHER_QUOTE,
  }),
})

export default λ(
  withStyles(styles),
  ReactRedux.connect(mapStateToprops, mapDispatchToProps),
)(FormContainer)
