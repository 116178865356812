/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import { Player, ControlBar } from 'video-react';

// MATERIAL UI COMPONENTS
import {
  withStyles,
  createStyles,
  Grid,
  Typography,
} from '../material'

import './reactVideo.css'

const styles = theme => createStyles({

})

const ua = navigator.userAgent.toLowerCase();
const isSafari = (ua.indexOf('safari/') > -1 && ua.indexOf('chrome') < 0);

class PreLoader extends PureComponent {
  componentDidMount() {
    // subscribe state change
    this.refs.player.subscribeToStateChange(this.handleStateChange);
    this.refs.player.playbackRate = 1.5;
    if (isSafari) {
      setTimeout(() => {
        const { player } = this.refs.player.getState()
        if (player.hasStarted === false) {
          const { onNextStep } = this.props
          onNextStep()
        }
      }, 2000);
    }
  }

  handleStateChange = (state) => {
    if (state.ended) {
      const { onNextStep } = this.props
      onNextStep()
    }
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Grid container direction="column" justify="center" alignItems="center" style={{ height: '40%' }}>
          <Grid item>
            <Typography style={{ color: '#ffffff', fontSize: 35, textAlign: 'center' }}>
            SHIPLUX
            </Typography>
            <Typography style={{
              color: '#ffffff', opacity: 0.4, fontSize: 16, textAlign: 'center',
            }}
            >
              Auto transport and logistics
            </Typography>
          </Grid>
        </Grid>
        <Player
          ref="player"
          muted
          autoPlay
          playsInline
          controls
          src="https://storage.googleapis.com/chatbot_video/13088882-sd.mov"
        >
          <ControlBar disableCompletely />
        </Player>
      </div>
    )
  }
}
export default withStyles(styles)(PreLoader)
