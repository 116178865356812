import {
  isEmpty,
} from 'ramda'

import {
  PERSONAL_INFO_UPDATE,
  SUBMIT_PERSONAL_INFO,
  START_ANOTHER_QUOTE,
} from '../actions'

const defaultState = {
  name: '',
  number: '(  )    -    ',
  email: '',
  errors: {
    hasErrors: false,
    name: false,
    number: false,
    email: false,
  },
}

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const PersonalInfo = (state = defaultState, action) => {
  switch (action.type) {
    case PERSONAL_INFO_UPDATE: {
      const { name, value } = action.payload
      return { ...state, [name]: value, errors: { ...state.errors, [name]: false } }
    }
    case SUBMIT_PERSONAL_INFO: {
      const {
        name,
        number,
        email,
      } = state

      const emailVaild = isValidEmail(email)

      if (!isEmpty(name) && number !== '(  )    -    ' && emailVaild) {
        return { ...state, errors: defaultState.errors }
      }

      return {
        ...state,
        errors: {
          hasErrors: true,
          name: isEmpty(name),
          number: number === '(  )    -    ',
          email: !emailVaild,
        },
      }
    }
    case START_ANOTHER_QUOTE:
      return { ...defaultState }
    default:
      return state
  }
}

export default PersonalInfo
