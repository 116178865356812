export default [
  'BENELLI',
  'MALAGUTI',
  'ZERO',
  'KAWASAKI',
  'URAL',
  'YAMAHA',
  'APRILIA',
  'HARLEY DAVIDSON',
  'MOTO GUZZI',
  'MODENAS',
  'SUZUKI',
  'HONDA',
  'ARCTIC CAT',
  'TRIUMPH',
  'LAVERDA',
  'BETA',
  'VICTORY',
  'BMW',
  'RIEJU MOTORS',
  'CAN-AM/ BRP',
  'HUSQVARNA',
  'INDIAN',
  'POLARIS',
  'DUCATI',
  'Big Bear Choppers',
  'HOREX',
  'SHERCO',
  'MZ',
  'CAGIVA',
  'LIFAN',
  'Christini',
  'CCM',
  'HIGHLAND',
  'MV AGUSTA',
  'HYOSUNG',
  'BIMOTA',
  'KANUNI',
  'BAJAJ',
  'MONDIAL',
  'AJP',
  'SACHS',
  'KYMCO',
  'Big Dog',
  'IZH',
  'MOTO MORINI',
  'BSA',
  'PEUGEOT',
  'EBR Motorcycles',
  'VESPA',
  'LEHMAN TRIKES',
  'ZUNDAPP',
  'NCR',
  'BUELL',
  'MAICO',
  'JUNAK',
  'JAWA',
  'GILERA',
  'VELOCETTE',
  'KTM',
  'OSSA',
  'DERBI',
  'Super SOCO',
  'ARIEL',
  'PIAGGIO',
  'AEON',
  'VINCENT HRD',
  'HUSABERG',
  'SIMSON',
  'GAS GAS',
  'MIKILON',
  'GEELY',
  'AJS',
  'MARUSHO-LILAC',
  'VYRUS',
  'ATK',
  'ROYAL ENFIELD',
  'Dafra Motos',
  'SYM',
  'CPI',
  'SCORPA',
  'DAELIM',
  'TGB',
  'BOSS HOSS',
  'ITALJET',
  'VENTO',
  'HODAKA',
  'CANNONDALE',
  'PGO',
  'EXCELSIOR',
  'MAGNI',
  'MBK',
  'CFMOTO',
  'MOTORHISPANIA',
  'DNEPR',
  'HESKETH',
  'Adler',
  'SWM',
  'MATCHLESS',
  'NORTON',
  'GG MOTORRAD',
  'KASINSKI',
  'BARIGO',
  'MEGELLI',
  'WAKAN',
  'AERMACCHI',
  'FISCHER',
  'VOXAN',
  'AMAZONAS',
  'HARTFORD',
  'MIDUAL',
  'BORILE',
  'MASH',
  'CONFEDERATE',
  'GHEZZI-BRIAN',
  'VOR',
  'VERUCCI',
  'WRM',
  'HENDERSON',
  'WHIZZER',
  'ALTA MOTORS',
  'LINHAI',
  'BROUGH SUPERIOR',
  'ECOSSE',
  'ENERGICA',
  'MOTUS',
  'MUNCH',
  'HERCULES',
  'CONDOR',
  'BRITTEN',
  'CHAK MOTORS',
  'MTT',
  'CARVER',
  'PETRONAS',
  'BFG',
  'CRS',
  'MOTOCZYSZ',
  'CEMEC',
]
