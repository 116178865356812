import React, { PureComponent } from 'react';

// MATERIAL UI COMPONENTS
import {
  withStyles,
  Fab,
  Typography,
  Grid,
  Grow,
} from '../material'

// Svg Icon
import { FabArrow } from '../icons'

const styles = theme => ({
  root: {
    backgroundColor: theme.backgroundColor,
    height: '100%',
  },
  children: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    height: 'calc(100% - 50px)',
    overflowY: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    '@media (max-height: 600px)': {
      height: 'calc(100% - 30px)',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  titleContainer: {
    height: 50,
    borderLeft: `1px solid ${theme.white}`,
    padding: 30,
    '@media (max-height: 600px)': {
      height: 30,
    },
  },
  title: {
    height: '100%',
  },
  fab: {
    margin: theme.spacing.unit,
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[1],
    '&:active': {
      boxShadow: theme.shadows[20],
    },
  },
  buttonSpacing: {
    marginTop: 20,
  },
})

class FormStep extends PureComponent {
  render() {
    const {
      classes,
      children,
      step,
      totalSteps,
      title,
      onNextStep,
    } = this.props

    return (
      <div className={classes.root}>
        <Grow in timeout={{ enter: 3000 }} style={{ transformOrigin: 'top left' }}>
          <div className={`${classes.titleContainer}`}>
            <Grow in timeout={{ enter: 2000 }} style={{ transformOrigin: 'left', transitionDelay: '700ms' }}>
              <Grid container className={`${classes.title}`} alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption" style={{ textAlign: 'right' }}>
                        {' '}
                        {`0${step}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" style={{ textAlign: 'right' }}>
                        {' '}
                        {`of 0${totalSteps}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grow>
          </div>
        </Grow>
        <Grid container className={classes.content} direction="column" justify="space-evenly">
          <Grow in timeout={{ enter: 2000 }} style={{ transformOrigin: 'top left', transitionDelay: '750ms' }}>
            <Grid item>
              {children}
            </Grid>
          </Grow>
          <Grow in timeout={{ enter: 2000 }} style={{ transformOrigin: 'bottom right', transitionDelay: '800ms' }}>
            <Grid item>
              <Grid container className={classes.buttonSpacing} direction="row" justify="flex-end">
                <Grid item>
                  <Fab className={classes.fab} onClick={onNextStep} aria-label="Next">
                    <FabArrow />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(FormStep)
