import React from 'react';
import { SvgIcon } from '../material';

export const OpenTrailer = (props = {}) => {
  const { fill, ...otherProps } = props
  return (
    <SvgIcon {...otherProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-850.000000, -4913.000000)" fill={fill}>
          <g transform="translate(130.000000, 3891.000000)">
            <g transform="translate(0.000000, 900.000000)">
              <g transform="translate(678.000000, 90.000000)">
                <g>
                  <g id="Icon" transform="translate(42.000000, 32.000000)">
                    <path d="M30.5,24.4363636 C30.5,22.4682216 28.9329966,20.8727273 27,20.8727273 C25.0670034,20.8727273 23.5,22.4682216 23.5,24.4363636 C23.5,26.4045056 25.0670034,28 27,28 C28.9329966,28 30.5,26.4045056 30.5,24.4363636 Z M25.5,24.4363636 C25.5,23.5928742 26.1715729,22.9090909 27,22.9090909 C27.8284271,22.9090909 28.5,23.5928742 28.5,24.4363636 C28.5,25.279853 27.8284271,25.9636364 27,25.9636364 C26.1715729,25.9636364 25.5,25.279853 25.5,24.4363636 Z" id="Shape" fillRule="nonzero" transform="translate(27.000000, 24.436364) scale(-1, 1) translate(-27.000000, -24.436364) " />
                    <path d="M22.5,24.4363636 C22.5,22.4682216 20.9329966,20.8727273 19,20.8727273 C17.0670034,20.8727273 15.5,22.4682216 15.5,24.4363636 C15.5,26.4045056 17.0670034,28 19,28 C20.9329966,28 22.5,26.4045056 22.5,24.4363636 Z M17.5,24.4363636 C17.5,23.5928742 18.1715729,22.9090909 19,22.9090909 C19.8284271,22.9090909 20.5,23.5928742 20.5,24.4363636 C20.5,25.279853 19.8284271,25.9636364 19,25.9636364 C18.1715729,25.9636364 17.5,25.279853 17.5,24.4363636 Z" id="Shape" fillRule="nonzero" transform="translate(19.000000, 24.436364) scale(-1, 1) translate(-19.000000, -24.436364) " />
                    <path d="M0,8 L24.9625716,7.03916199 C25.5032693,7.01834991 25.958463,7.4398005 25.979275,7.98049822 C25.9797583,7.99305361 25.98,8.00561713 25.98,8.01818182 L25.98,8.07411427 C25.98,8.61143387 25.5553847,9.05270757 25.0184627,9.07337431 L0,10.0363636 L0,8 Z" id="Rectangle" fillRule="nonzero" transform="translate(12.990000, 8.537393) scale(-1, 1) translate(-12.990000, -8.537393) " />
                    <path d="M25.9698972,7.99856735 C27.6564604,8.61242618 30.1129009,9.50649738 33.3392185,10.680781 C33.8581964,10.8696735 34.1257835,11.4435158 33.936891,11.9624937 C33.7479985,12.4814716 33.1741561,12.7490587 32.6551782,12.5601662 C29.5746682,11.4389522 27.2642856,10.5980418 25.7240306,10.0374348" id="Path" fillRule="nonzero" />
                    <rect id="Rectangle" fillRule="nonzero" transform="translate(1.000000, 10.181818) scale(-1, 1) translate(-1.000000, -10.181818) " x="0" y="0.509090909" width="2" height="19.3454545" rx="1" />
                    <rect id="Rectangle" fillRule="nonzero" transform="translate(17.000000, 18.836364) scale(-1, 1) translate(-17.000000, -18.836364) " x="0" y="17.8181818" width="34" height="2.03636364" rx="1.01818182" />
                    <path d="M4,19.3454545 C4,18.7831283 3.55228475,18.3272727 3,18.3272727 C2.44771525,18.3272727 2,18.7831283 2,19.3454545 L2,21.8909091 C2,22.4532354 2.44771525,22.9090909 3,22.9090909 C3.55228475,22.9090909 4,22.4532354 4,21.8909091 L4,19.3454545 Z" id="Path" transform="translate(3.000000, 20.618182) scale(-1, 1) translate(-3.000000, -20.618182) " />
                    <path d="M29.6317569,21.8948403 C29.3577469,22.3830763 29.5243436,23.0050374 30.0038611,23.2840294 C30.4833785,23.5630213 31.0942332,23.3933956 31.3682431,22.9051597 L33.3682431,19.3415233 C33.6422531,18.8532873 33.4756564,18.2313262 32.9961389,17.9523342 C32.5166215,17.6733423 31.9057668,17.842968 31.6317569,18.331204 L29.6317569,21.8948403 Z" id="Path" transform="translate(31.500000, 20.618182) scale(-1, 1) translate(-31.500000, -20.618182) " />
                    <path d="M14.6317569,22.9051597 C14.9057669,23.3933956 15.5166215,23.5630213 15.996139,23.2840294 C16.4756564,23.0050374 16.6422531,22.3830763 16.3682432,21.8948403 L14.3682432,18.331204 C14.0942332,17.842968 13.4833785,17.6733423 13.0038611,17.9523342 C12.5243436,18.2313262 12.3577469,18.8532873 12.6317569,19.3415233 L14.6317569,22.9051597 Z" id="Path" transform="translate(14.500000, 20.618182) scale(-1, 1) translate(-14.500000, -20.618182) " />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
OpenTrailer.defaultProps = {
  fill: '#ffffff',
}

export const EnclosedTrailer = (props = {}) => {
  const { fill, ...otherProps } = props
  return (
    <SvgIcon {...otherProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-239.000000, -512.000000)" fill={fill}>
          <g transform="translate(80.000000, 422.000000)">
            <g transform="translate(159.000000, 90.000000)">
              <path d="M30.5,18.4186047 C30.5,16.4406546 28.9329966,14.8372093 27,14.8372093 C25.0670034,14.8372093 23.5,16.4406546 23.5,18.4186047 C23.5,20.3965547 25.0670034,22 27,22 C28.9329966,22 30.5,20.3965547 30.5,18.4186047 Z M25.5,18.4186047 C25.5,17.5709118 26.1715729,16.8837209 27,16.8837209 C27.8284271,16.8837209 28.5,17.5709118 28.5,18.4186047 C28.5,19.2662975 27.8284271,19.9534884 27,19.9534884 C26.1715729,19.9534884 25.5,19.2662975 25.5,18.4186047 Z" id="Shape" fillRule="nonzero" transform="translate(27.000000, 18.418605) scale(-1, 1) translate(-27.000000, -18.418605) " />
              <path d="M22.5,18.4186047 C22.5,16.4406546 20.9329966,14.8372093 19,14.8372093 C17.0670034,14.8372093 15.5,16.4406546 15.5,18.4186047 C15.5,20.3965547 17.0670034,22 19,22 C20.9329966,22 22.5,20.3965547 22.5,18.4186047 Z M17.5,18.4186047 C17.5,17.5709118 18.1715729,16.8837209 19,16.8837209 C19.8284271,16.8837209 20.5,17.5709118 20.5,18.4186047 C20.5,19.2662975 19.8284271,19.9534884 19,19.9534884 C18.1715729,19.9534884 17.5,19.2662975 17.5,18.4186047 Z" id="Shape" fillRule="nonzero" transform="translate(19.000000, 18.418605) scale(-1, 1) translate(-19.000000, -18.418605) " />
              <path d="M32,2.04651163 L32,11.7674419 L2,11.7674419 L2,2.04651163 L32,2.04651163 Z M0,1.02325581 L0,12.7906977 C0,13.3558263 0.44771525,13.8139535 1,13.8139535 L33,13.8139535 C33.5522847,13.8139535 34,13.3558263 34,12.7906977 L34,1.02325581 C34,0.458127233 33.5522847,0 33,0 L1,0 C0.44771525,0 0,0.458127233 0,1.02325581 Z" id="Shape" fillRule="nonzero" transform="translate(17.000000, 6.906977) scale(-1, 1) translate(-17.000000, -6.906977) " />
              <path d="M4,13.3023256 C4,12.737197 3.55228475,12.2790698 3,12.2790698 C2.44771525,12.2790698 2,12.737197 2,13.3023256 L2,15.8604651 C2,16.4255937 2.44771525,16.8837209 3,16.8837209 C3.55228475,16.8837209 4,16.4255937 4,15.8604651 L4,13.3023256 Z" id="Path" transform="translate(3.000000, 14.581395) scale(-1, 1) translate(-3.000000, -14.581395) " />
              <path d="M29.6317569,15.864416 C29.3577469,16.355085 29.5243436,16.9801455 30.0038611,17.2605279 C30.4833785,17.5409101 31.0942332,17.3704391 31.3682431,16.8797701 L33.3682431,13.2983747 C33.6422531,12.8077057 33.4756564,12.1826451 32.9961389,11.9022628 C32.5166215,11.6218805 31.9057668,11.7923516 31.6317569,12.2830206 L29.6317569,15.864416 Z" id="Path" transform="translate(31.500000, 14.581395) scale(-1, 1) translate(-31.500000, -14.581395) " />
              <path d="M14.6317568,16.8797701 C14.9057668,17.3704391 15.5166215,17.5409101 15.9961389,17.2605279 C16.4756564,16.9801455 16.6422531,16.355085 16.3682431,15.864416 L14.3682431,12.2830206 C14.0942331,11.7923516 13.4833785,11.6218805 13.003861,11.9022628 C12.5243436,12.1826451 12.3577469,12.8077057 12.6317568,13.2983747 L14.6317568,16.8797701 Z" id="Path" transform="translate(14.500000, 14.581395) scale(-1, 1) translate(-14.500000, -14.581395) " />
            </g>
          </g>
        </g>
      </g>

    </SvgIcon>
  )
}

EnclosedTrailer.defaultProps = {
  fill: '#000000',
}

export const FabArrow = (props = {}) => (
  <SvgIcon {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-275.000000, -412.000000)" fillRule="nonzero">
        <g transform="translate(258.000000, 394.000000)">
          <g transform="translate(28.000000, 28.000000) rotate(-90.000000) translate(-28.000000, -28.000000) translate(19.000000, 17.000000)">
            <rect fill="#1C1816" x="8" y="0" width="2" height="21" />
            <polyline stroke="#1C1816" strokeWidth="2" points="-1.98951966e-13 13 9 22 18 13" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export const AddArrow = (props = {}) => (
  <SvgIcon {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-460.000000, -5762.000000)" fill="#FFFFFF">
        <g transform="translate(130.000000, 5634.000000)">
          <g transform="translate(330.000000, 128.000000)">
            <path d="M8,8 L8,0 L9,0 L9,8 L17,8 L17,9 L9,9 L9,17 L8,17 L8,9 L0,9 L0,8 L8,8 Z" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export const Car = (props = {}) => (
  <SvgIcon {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.4">
      <g transform="translate(-108.000000, -577.000000)" fill="#FFFFFF">
        <g transform="translate(50.000000, 470.000000)">
          <g transform="translate(58.000000, 107.000000)">
            <path d="M3.82352941,10.8 C3.82352941,9.9163444 4.50827038,9.2 5.35294118,9.2 C6.19761197,9.2 6.88235294,9.9163444 6.88235294,10.8 C6.88235294,11.6836556 6.19761197,12.4 5.35294118,12.4 C4.50827038,12.4 3.82352941,11.6836556 3.82352941,10.8 Z M8.41176471,10.8 C8.41176471,9.0326888 7.04228276,7.6 5.35294118,7.6 C3.66359959,7.6 2.29411765,9.0326888 2.29411765,10.8 C2.29411765,12.5673112 3.66359959,14 5.35294118,14 C7.04228276,14 8.41176471,12.5673112 8.41176471,10.8 Z" id="Shape" fillRule="nonzero" />
            <path d="M18.3529412,10.8 C18.3529412,9.9163444 19.0376821,9.2 19.8823529,9.2 C20.7270237,9.2 21.4117647,9.9163444 21.4117647,10.8 C21.4117647,11.6836556 20.7270237,12.4 19.8823529,12.4 C19.0376821,12.4 18.3529412,11.6836556 18.3529412,10.8 Z M22.9411765,10.8 C22.9411765,9.0326888 21.5716945,7.6 19.8823529,7.6 C18.1930114,7.6 16.8235294,9.0326888 16.8235294,10.8 C16.8235294,12.5673112 18.1930114,14 19.8823529,14 C21.5716945,14 22.9411765,12.5673112 22.9411765,10.8 Z" id="Shape" fillRule="nonzero" />
            <path d="M1.52941176,10.4 L1.52941176,6.6919922 L6.2257928,5.9919596 C6.41551231,5.96360592 6.5881395,5.8617616 6.70949676,5.70659024 L9.92173677,1.6 L18.6960314,1.6 L21.1489647,5.6295004 C21.2893326,5.86025896 21.5326644,6 21.7941176,6 L24.4705882,6 L24.4705882,10.4 L22.5588235,10.4 C22.1364881,10.4 21.7941176,10.7581722 21.7941176,11.2 C21.7941176,11.6418278 22.1364881,12 22.5588235,12 L25.2352941,12 C25.6576295,12 26,11.6418278 26,11.2 L26,5.2 C26,4.7581722 25.6576295,4.4 25.2352941,4.4 L22.2200786,4.4 L19.7628,0.370499617 C19.6224321,0.139741081 19.3791003,1.07944637e-08 19.1176471,1.0507074e-08 L9.55882353,0 C9.32946289,-2.5211353e-10 9.11221365,0.107701421 8.96697383,0.293409768 L5.72215972,4.45098376 L0.65656014,5.2080404 C0.279828751,5.26434312 0,5.60187972 0,6 L0,11.2 C0,11.6418278 0.342370485,12 0.764705882,12 L2.67647059,12 C3.09880599,12 3.44117647,11.6418278 3.44117647,11.2 C3.44117647,10.7581722 3.09880599,10.4 2.67647059,10.4 L1.52941176,10.4 Z" id="Path" />
            <path d="M21.7941176,6 C22.216453,6 22.5588235,5.6418278 22.5588235,5.2 C22.5588235,4.7581722 22.216453,4.4 21.7941176,4.4 L6.11764706,4.4 C5.69531166,4.4 5.35294118,4.7581722 5.35294118,5.2 C5.35294118,5.6418278 5.69531166,6 6.11764706,6 L21.7941176,6 Z" id="Path" />
            <path d="M7.97843137,10.4 C7.58425167,10.4 7.26470588,10.7581722 7.26470588,11.2 C7.26470588,11.6418278 7.58425167,12 7.97843137,12 L17.2568627,12 C17.6510424,12 17.9705882,11.6418278 17.9705882,11.2 C17.9705882,10.7581722 17.6510424,10.4 17.2568627,10.4 L7.97843137,10.4 Z" id="Path" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export const Route = (props = {}) => (
  <SvgIcon {...props}>
    <defs>
      <linearGradient x1="37.804119%" y1="1.74019198%" x2="17.9781292%" y2="76.1220062%" id="linearGradient-1">
        <stop stopColor="#D9A682" offset="0%" />
        <stop stopColor="#FFFFFF" offset="100%" />
      </linearGradient>
      <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" id="radialGradient-2">
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="0%" />
        <stop stopColor="#FFFFFF" offset="100%" />
      </radialGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-75.000000, -142.000000)">
        <g transform="translate(50.000000, 30.000000)">
          <g transform="translate(25.000000, 100.000000)">
            <g transform="translate(0.000000, 12.000000)">
              <circle id="O" fill="#D9A682" cx="10" cy="3" r="3" />
              <path d="M10.2268813,5.50197996 L9.77311872,31.49802" id="|" stroke="url(#linearGradient-1)" strokeLinecap="square" transform="translate(10.000000, 18.500000) rotate(-1.000000) translate(-10.000000, -18.500000) " />
              <circle id="O" fill="url(#radialGradient-2)" opacity="0.2" cx="10" cy="33" r="10" />
              <circle id="O" fill="#FFFFFF" cx="10" cy="33" r="3" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)


export const ButtonArrow = (props = {}) => (
  <SvgIcon {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-255.000000, -1058.000000)" fillRule="nonzero">
        <g transform="translate(80.000000, 1040.000000)">
          <g transform="translate(186.000000, 28.000000) rotate(-90.000000) translate(-186.000000, -28.000000) translate(177.000000, 17.000000)">
            <rect id="|" fill="#FFFFFF" x="8" y="0" width="2" height="21" />
            <polyline id="&gt;" stroke="#FFFFFF" strokeWidth="2" points="-1.98951966e-13 13 9 22 18 13" />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)
