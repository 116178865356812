import React, { PureComponent } from 'react';
import { map, addIndex, isEmpty } from 'ramda'

// MATERIAL UI COMPONENTS
import {
  withStyles,
  TextField,
  MenuItem,
  // Grid,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  Paper,
  createStyles,
} from '../material'

import AutoComplete from '../autocomplete'
import Conditional from '../conditional'
import Loader from '../loader'

import { isObject } from '../../utils'

import { AddArrow } from '../icons'

/** @typedef {{ label: string | number, value: string | number }} MenuPayload */

/** returns am array of menuItems given a list of menu payloads of type { label: string | number, value: string | number } | string | number */
const mapMenuData = addIndex(map)((/** @type { MenuPayload | string | number } */payload, index) => (
  <MenuItem value={isObject(payload) ? /** @type { MenuPayload } */(payload).value : /** @type { string | number } */(payload)} key={index}>
    {isObject(payload) ? /** @type { MenuPayload } */(payload).value : payload}
  </MenuItem>
))

const styles = theme => createStyles({
  root: {
    backgroundColor: '#1c1816',
    height: '100%',
  },
  switchLabel: {
    marginTop: 24,
  },
  switchFont: {
    fontSize: 15,
  },
  switchActive: {
    color: theme.white,
    opacity: 1,
  },
  switchNonActive: {
    color: theme.white,
    opacity: 0.5,
  },
  switchBase: {
    height: 22,
    width: 46,
  },
  switchBaseIcon: {
    backgroundColor: '#000000',
    width: 14,
    height: 14,
  },
  switchBaseBar: {
    backgroundColor: theme.white,
  },
  checked: {
    transform: 'translateX(14px)',
    '& + $switchBaseBar': {
      opacity: 0.5,
    },
  },
  switchBaseColorPrimary: {
    '&$checked': {
      color: 'black',
      '& + $switchBaseBar': {
        backgroundColor: theme.white,
        opacity: 1,
      },
    },
    autoCompleteInput: {
      marginTop: 50,
    },
  },
  // border: {
  //   '&:before': {
  //     borderBottom: '1px solid #ffffff',
  //   },
  // },
  vehiclePaper: {
    padding: 10,
    marginLeft: -30,
    marginRight: -30,
    marginBottom: 10,
    borderRadius: 0,
    '@media (max-height: 600px)': {
      marginLeft: -20,
      marginRight: -20,
    },
  },
  falseButtonContent: {
    color: theme.white,
  },
  falseButton: {
    marginTop: 30,
    padding: 0,
    textAlign: 'left',
  },
  vehiclePaperPrimary: {
    color: theme.white,
    '@media (max-height: 600px)': {
      fontSize: 12,
    },
  },
  vehiclePaperSecondary: {
    color: theme.white,
    opacity: 0.4,
    '@media (max-height: 600px)': {
      fontSize: 12,
    },
  },
});

// update to just be an input for year/make/model
class VehicleInfo extends PureComponent {
  render() {
    const {
      classes,
      currentVehicle: {
        year,
        validYear,
        make,
        validMake,
        model,
        running,
      },
      vehicles,
      yearAutoComplete,
      makeAutoComplete,
      modelMenu,
      handleChange,
      onAddVehicle,
      errors,
    } = this.props

    const switchOverride = {
      switchBase: classes.switchBase,
      icon: classes.switchBaseIcon,
      bar: classes.switchBaseBar,
      colorPrimary: classes.switchBaseColorPrimary,
      checked: classes.checked,
    }

    const yearError = !isEmpty(year) && !validYear && isEmpty(yearAutoComplete.suggestions)
    const makeError = !isEmpty(make) && !validMake && isEmpty(makeAutoComplete.suggestions)
    const yearLabel = yearError
      ? 'Vehicle Year (invalid year)'
      : errors.year.status
        ? errors.year.label
        : 'Vehicle Year'
    const makeLabel = makeError
      ? 'Vehicle Make (make not found)'
      : errors.make.status
        ? errors.make.label
        : 'Vehicle Make'
    const modelLabel = errors.model.status ? errors.model.label : 'Vehicle Model'

    return (
      <div className={classes.root}>
        {vehicles.map(
          (v, i) => (
            <Paper className={classes.vehiclePaper}>
              <Grid container direction="row" justify="flex-start">
                <Grid item>
                  <Typography className={classes.vehiclePaperPrimary}>
                    {v.make}
                  </Typography>
                  <Typography className={classes.vehiclePaperPrimary}>
                    {v.year}
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: 5 }}>
                  <Typography className={classes.vehiclePaperSecondary}>
                    {v.model}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ),
        )}
        <AutoComplete
          InputProps={{
            type: 'text',
            label: yearLabel,
            margin: 'normal',
            error: yearError || errors.year.status,
          }}
          value={year}
          suggestions={yearAutoComplete.suggestions}
          handleChange={handleChange('year')}
          handleSuggestionsFetchRequested={yearAutoComplete.getSuggestions}
          handleSuggestionsClearRequested={yearAutoComplete.clearSuggesions}
        />
        <AutoComplete
          InputProps={{
            type: 'text',
            label: makeLabel,
            margin: 'normal',
            error: makeError || errors.make.status,
          }}
          classes={{ input: classes.autoCompleteInput }}
          value={make}
          suggestions={makeAutoComplete.suggestions}
          handleChange={handleChange('make')}
          handleSuggestionsFetchRequested={makeAutoComplete.getSuggestions}
          handleSuggestionsClearRequested={makeAutoComplete.clearSuggesions}
        />
        <Conditional condition={modelMenu.loaded}>
          {
            () => (

              <TextField
                select
                label={modelLabel}
                error={errors.model.status}
                margin="normal"
                className={classes.textField}
                value={model}
                onChange={event => handleChange('model')(event.target.value)}
                fullWidth
                SelectProps={{
                  // autoWidth: true,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        minWidth: 300,
                      },
                    },
                  },

                }}
              >
                {mapMenuData(modelMenu.data)}
              </TextField>

            )
          }
        </Conditional>
        <Conditional condition={make === 'Custom'}>
          <TextField
            label="Model Info"
            margin="normal"
            value={model}
            onChange={event => handleChange('model')(event.target.value)}
            fullWidth
          />
        </Conditional>
        <Conditional condition={modelMenu.loading}>
          {
            () => (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Loader size={24} />
                </Grid>
              </Grid>
            )
          }
        </Conditional>
        <Conditional condition={!isEmpty(model) || make === 'Custom'}>

          <FormControlLabel
            className={classes.switchLabel}
            classes={{ label: `${running ? classes.switchActive : classes.switchNonActive} ${classes.switchFont}` }}
            control={(
              <Switch
                classes={switchOverride}
                checked={running}
                onChange={event => handleChange('running')(event.target.checked)}
                color="primary"
              />
            )}
            label="Running"
          />

        </Conditional>
        <Conditional condition={!isEmpty(model) || make === 'Custom'}>
          <div>
            <Button className={classes.falseButton}>
              <Grid container direction="column" justify="flex-start" onClick={onAddVehicle}>
                <Grid item>
                  <AddArrow />
                </Grid>
                <Grid item>
                  <div>
                    <Typography className={classes.falseButtonContent}> Add Another</Typography>
                    <Typography className={classes.falseButtonContent}> Vehicle</Typography>
                  </div>
                </Grid>
              </Grid>
            </Button>
          </div>

        </Conditional>
      </div>
    )
  }
}

export default withStyles(styles)(VehicleInfo)
