import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';

// MATERIAL UI COMPONENTS
import {
  withStyles,
  // TextField,
  // MenuItem,
  Grid,
  Paper,
  createStyles,
  Typography,
  Divider,
  // FormControlLabel,
  // Switch,
  Fab,
} from '../material'

import Conditional from '../conditional'
import Loader from '../loader'

import mapStyles from './mapStyles'
import { getBounds } from './map-utils'
// import { FabArrow } from '../icons'
import { Car, Route, ButtonArrow } from '../icons'

import { addDaysToFormattedDate } from '../../utils'

const addDaysToQuoteDate = addDaysToFormattedDate(5)

const styles = theme => createStyles({
  root: {
    backgroundColor: '#000000',
    height: 'calc(100vh + 600px)',
  },
  with: {
    width: '100%',
  },
  title1: {
    height: 50,
    borderLeft: `1px solid ${theme.white}`,
    padding: 30,
  },
  title2: {
    height: 50,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: 30,
  },
  content: {
    top: 0,
    fontSize: 25,
    position: 'absolute',
    zIndex: 5000,
    height: 'calc(100vh + 400px)',
    width: '100vw',
    paddingTop: 50,
    paddingLeft: 50,
  },
  paper: {
    backgroundColor: 'rgba(47,45,44,.9)',
  },
  button: {
    width: '100%',
  },
  Paper2Header: {
    color: theme.palette.primary.main,
  },
  Paper2Column1: {
    color: theme.white,
  },
  quoteDetailBody: {
    paddingLeft: 30,
  },
  QuoteDetailBodyColumn1: {
    color: theme.white,
    opacity: 0.4,
    fontSize: '15px',
  },
  QuoteDetailBodySpacing: {
    marginBottom: 20,
  },
  QuoteDetailPriceSpacing: {
    marginTop: 30,
  },
  QuoteDetailBodyColumn2: {
    color: theme.white,
    fontSize: '15px',
  },
  QuotePricingBody: {
    color: '#D9A682',
    fontSize: 16,
  },
  dividerStyling: {
    'background-image': 'linear-gradient(to right, rgba(255,255,255,1), rgba(0,0,0,1))',
    opacity: 0.6,
  },
  iconStyling: {
    paddingLeft: 14,
  },
  iconHeader: {
    height: '2em',
    paddingTop: 5,
  },
  textHeaderBody1: {
    color: theme.shipluxGold,
  },
  headerContainer: {
    marginLeft: 25,
    height: '100%',
  },
  paperHeaderSize: {
    height: 190,
  },
  fabStyling: {
    margin: theme.spacing.unit,
  },
  buttonContainer: {
    height: 150,
    marginTop: -75,
    '@media (max-height: 600px)': {
      height: 150,
      marginTop: 10,
    },
    '@media (min-height: 800px)': {
      height: 150,
      marginTop: -175,
    },
  },
});

const createMapOptions = maps => ({
  disableDefaultUI: true,
  styles: mapStyles,
  gestureHandling: 'none',
  zoomControl: false,
  scrollwheel: false,
  panControl: false,
})
// update to just be an input for year/make/model
class QuoteDetails extends Component {
  timeOut = null

  state = {
    mapLoaded: false,
    routeDistance: null,
    estimatedArrival: null,
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearInterval(this.timeOut)
    }
  }

  handleApiLoaded = ({ map, maps }) => {
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();
    directionsDisplay.setMap(map);
    const { origin, destination, shipDate } = this.props
    directionsService.route({
      origin: origin.location,
      destination: destination.location,
      waypoints: [],
      optimizeWaypoints: true,
      travelMode: 'DRIVING',
    }, (response, status) => {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        const path = maps.geometry.encoding.decodePath(response.routes[0].overview_polyline)
        const car1 = {
          path: maps.SymbolPath.CIRCLE,
          strokeColor: '#1C1816',
          fillColor: '#1C1816',
          fillOpacity: 1,
          rotation: 90,
          scale: 1,
        };
        const car2 = {
          path: maps.SymbolPath.CIRCLE,
          fillColor: '#FFFFFF',
          fillOpacity: 1,
          strokeColor: '#FFFFFF',
          rotation: 90,
          scale: 1,

        }
        const line = new maps.Polyline({
          path,
          icons: [{
            icon: car1,
            offset: '100%',
          },
          {
            icon: car2,
            offset: '100%',
          }],
          zIndex: Infinity,
          strokePosition: maps.StrokePosition.INSIDE,
          geodesic: true,
          map,
        });
        const routeDistance = Number(response.routes[0].legs[0].distance.text.match(/\d+/g).join(''))
        const routeDays = Math.round(routeDistance / 500)
        this.setState({
          mapLoaded: true,
          routeDistance,
          estimatedArrival: addDaysToFormattedDate(routeDays + 5)(shipDate),
        })
        this.animateCircle(line);
      }
    });
  }

  animateCircle = (line) => {
    let count = 0
    this.timeOut = setInterval(() => {
      count = (count + 1) % 500
      const icons = line.get('icons')
      icons[0].offset = `${(count / 5)}%`
      icons[1].offset = `${(count / 5)}%`
      line.set('icons', icons)
    })
  }

  render() {
    const {
      classes,
      origin,
      destination,
      onNextStep,
      vehiclesFormatted,
      trailer,
      price,
      pickupFormatted,
      support,
    } = this.props

    const {
      mapLoaded,
      routeDistance,
      estimatedArrival,
    } = this.state

    const size = { width: window.innerWidth, height: window.innerHeight }
    const { center, zoom } = fitBounds(
      getBounds({ origin: origin.location, destination: destination.location }),
      size,
    )

    return (
      <div className={classes.root}>
        <Grid container direction="column" justify="space-between" alignItems="center" className={`${classes.content} ${classes.with}`}>
          <Conditional condition={mapLoaded}>
            {
              () => (
                <React.Fragment>
                  <Grid item className={classes.with}>
                    <Paper className={`${classes.paper} ${classes.paperHeaderSize}`} elevation={1}>
                      <Grid container direction="column" className={classes.headerContainer} justify="space-evenly">
                        <Grid item style={{ paddingLeft: 5 }}>
                          <Typography variant="h6">Shipping route</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item>
                              <Route className={classes.iconHeader} viewBox="0 0 20 43" />
                            </Grid>
                            <Grid item style={{ paddingLeft: 15 }}>
                              <Typography style={{ color: '#D9A682', fontSize: 16 }}>{origin.originLabel}</Typography>
                              <Typography style={{ color: '#ffffff', fontSize: 16 }}>{destination.destinationLabel}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Paper>
                  </Grid>
                  <Grid item className={classes.with}>
                    <Paper className={classes.paper} elevation={1}>
                      <Grid className={classes.title2} container alignItems="center" justify="space-between">
                        <Grid item>
                          <Typography variant="h6" className={classes.Paper2Header}>Your estimate quote</Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="column" justify="space-evenly" className={classes.quoteDetailBody}>
                        <Grid item className={classes.QuoteDetailBodySpacing}>
                          {
                              vehiclesFormatted.map((v, i) => (
                                <Grid container direction="row" justify="flex-start" key={`${i + 1}vehichle`} className={classes.QuoteDetailBodySpacing}>
                                  <Grid item xs={1}>
                                    <Typography className={classes.QuoteDetailBodyColumn1}>
                                      {i + 1}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5} className={classes.iconStyling}>
                                    <Car viewBox="0 0 26 14" />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography className={classes.QuoteDetailBodyColumn2}>
                                      {v.primaryLabel}
                                    </Typography>
                                    <Typography className={classes.QuoteDetailBodyColumn2}>
                                      {v.secondaryLabel}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))
                          }
                        </Grid>
                        <Grid item className={classes.QuoteDetailBodySpacing}>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn1}>
                                Trailer
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn2}>
                                {trailer}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.QuoteDetailBodySpacing}>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn1}>
                              Est. pickup:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn2}>
                                {pickupFormatted}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.QuoteDetailBodySpacing}>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn1}>
                              Est. delivery:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn2}>
                                {`${estimatedArrival} - ${addDaysToQuoteDate(estimatedArrival)}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.QuoteDetailBodySpacing}>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn1}>
                                Distance:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.QuoteDetailBodyColumn2}>
                                {`${routeDistance} miles`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.QuoteDetailBodySpacing}>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item>
                              <Divider />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider className={classes.dividerStyling} />
                        <Grid item className={`${classes.QuoteDetailPriceSpacing} ${classes.QuoteDetailBodySpacing}`}>
                          <Grid container direction="row" justify="flex-start">
                            <Grid item xs={6}>
                              <Typography className={classes.QuotePricingBody}>
                                Estimate
                              </Typography>
                              <Typography className={classes.QuotePricingBody}>
                                 Price:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.QuotePricingBody}>
                                {price}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid container direction="column" justify="space-evenly" alignItems="center" className={classes.buttonContainer}>
                    <Grid item>
                      <Fab style={{ color: '#ffffff', backgroundColor: '#D9A682' }} variant="extended" onClick={onNextStep}>
                          BOOK AN ORDER
                        <ButtonArrow style={{ marginLeft: 5 }} viewBox="0 0 24 20" />
                      </Fab>
                    </Grid>
                    <Grid item />
                    <Grid item>
                      <Fab style={{ color: '#D9A682', backgroundColor: '#000000', border: '1px solid rgba(217,166,130,0.5)' }} variant="extended" href={`tel:${support}`}>
                          SPEAK TO SUPPORT
                      </Fab>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            }
          </Conditional>
        </Grid>
        <Conditional condition={mapLoaded === false}>

          <Grid
            container
            direction="column"
            justify="space-evenly"
            alignItems="center"
            style={
              {
                height: '100vh', width: '100vw', top: 0, position: 'fixed',
              }
            }
          >
            <Grid item>
              <Loader size={40} />
            </Grid>
          </Grid>

        </Conditional>
        <div style={
          {
            height: '100vh', width: '100vw', top: 0, position: 'fixed',
          }
        }
        >
          <GoogleMap
            center={center}
            zoom={zoom}
            bootstrapURLKeys={{ key: 'AIzaSyBiRcneIA8l9nWyJEtLfk6GyzPIYAJ_WQo' }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={this.handleApiLoaded}
            options={createMapOptions}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(QuoteDetails)
