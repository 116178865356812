import queryString from 'query-string'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const getUrl = endpoint => `${isLocalhost ? 'http://localhost:3500' : 'https://joybot.shiplux.com'}${endpoint}`

const postFetchConfig = data => ({
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
})

export const getFetch = (url, json = true) =>
  data => fetch(getUrl(`${url}${data ? '?' : ''}${queryString.stringify(data)}`))
    .then(res => json ? res.json() : res)
export const postFetch = (url, json = true) =>
  data => fetch(getUrl(url), postFetchConfig(data))
    .then(res => json ? res.json() : res)

export const createQuote = postFetch('/quote/create')
export const confirmQuote = postFetch('/quote/confirm')
export const getVehicleModel = getFetch('/shipping/motorcycles/models/get')
export const getLocationPredictions = getFetch('/shipping/location/search')
