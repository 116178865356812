import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { last } from 'ramda';
import AppSaga from '../sagas';
import rootReducer from '../reducers';
import sagaActionMiddleware from './sagaActionMiddleware'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
/**
 * @type { typeof import('redux').compose }
 */
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * !!!IMPORTANT!!! it is important that sagaMiddleware is last in the applied middleware.
 *  This is do that we can assure that if the state is accessed from the sagaMiddleware
 *   the state will reflect the updated application state after the action is dispatched.
*/
const middlewares = [routerMiddleware(history), sagaMiddleware];

// for production only since we want to see the action in redux dev tools
if (process.env.NODE_ENV === 'production') {
  middlewares.push(sagaActionMiddleware)
}

if (process.env.NODE_ENV !== 'production' && last(middlewares) !== sagaMiddleware) {
  throw Error('In store.js of store/index.js sagaMiddleware must be last middleware in the chain of redux middlewares');
}

const enhancers = composeEnhancers(applyMiddleware(...middlewares));
const AppStore = createStore(rootReducer(history), enhancers);

export { AppSaga, sagaMiddleware, history }
export default AppStore;