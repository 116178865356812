import {
  QUOTE_RESPONSE,
  START_ANOTHER_QUOTE,
} from '../actions'

const defaultState = {
  loading: true,
  quoteDetails: null,
  error: null,
}

const QuoteDetails = (state = defaultState, action) => {
  switch (action.type) {
    case QUOTE_RESPONSE: {
      const { quoteDetails, error } = action.payload
      return {
        ...state, loading: false, quoteDetails, error,
      }
    }
    case START_ANOTHER_QUOTE:
      return { ...defaultState }
    default:
      return state
  }
}

export default QuoteDetails
