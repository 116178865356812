import {
  compose as λ,
  equals,
  type,
} from 'ramda'
import { DateTime } from 'luxon'

export const formatIsoDate = date => DateTime.fromISO(date).toFormat('MM/dd/yyyy')

export const addDaysToFormattedDate =
  days =>
    date => DateTime.fromFormat(date, 'MM/dd/yyyy').plus({ days }).toFormat('MM/dd/yyyy')
export const isObject = λ(equals('Object'), type)
